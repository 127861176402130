import { useQuery } from 'react-query';
import { getWebDdpCarousel } from '../graphql/ddp-carousel.2';

export const useGetFirstPurchaseDeals = () =>
  useQuery('getFirstPurchaseDeals', () => getWebDdpCarousel(), {
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
  });

import { useEffect } from 'react';
import { InfiniteData, QueryOptions, useInfiniteQuery, useQueryClient } from 'react-query';
import { useRouter } from 'next/router';

import { RequestContext } from '@common/api-request';
import { useIsomorphicLayoutEffect, useLatest } from '@common/hooks';

import { getPageInfoForWeb } from '../graphql/page_info.2';
import { GetPageInfoForWeb_page_info } from '../graphql/page_info_types.2';

const page_info_session_key = 'page_info_session_key';

export function usePageInfoQuery({ page_id }: { page_id: string }) {
  const queryClient = useQueryClient();

  const query_option = pageInfoQueryOptions(page_id);
  const queryKeyRef = useLatest(query_option.queryKey);
  const { events } = useRouter();
  useEffect(() => {
    let href = window.location.pathname + window.location.search;
    const handle = () => {
      const isReload = href === window.location.pathname + window.location.search;
      if (isReload) {
        sessionStorage.removeItem(page_info_session_key);
      }
    };
    const handleLoad = (url: string) => {
      href = url;
    };
    events.on('routeChangeStart', handleLoad);
    window.addEventListener('beforeunload', handle);
    return () => {
      events.off('routeChangeComplete', handleLoad);
      window.removeEventListener('beforeunload', handle);
    };
  }, [queryClient, queryKeyRef, events]);
  useIsomorphicLayoutEffect(() => {
    if (typeof sessionStorage !== 'undefined' && typeof queryKeyRef.current !== 'undefined') {
      const sessionData = sessionStorage.getItem(page_info_session_key);
      if (typeof sessionData === 'string') {
        const sessionRecord = JSON.parse(sessionData);
        if (isSavedPageInfoData(sessionRecord, page_id)) {
          queryClient.setQueryData(['page_info', page_id], sessionRecord[page_id]);
        }
      }
    }
  }, [queryClient, queryKeyRef]);

  return useInfiniteQuery(query_option);
}

type PageInfoQueryOptions = (
  page_id: string,
  context?: RequestContext,
) => QueryOptions<
  GetPageInfoForWeb_page_info | null,
  unknown,
  InfiniteData<GetPageInfoForWeb_page_info | null>,
  string[]
>;

/** @public */
export const pageInfoQueryOptions: PageInfoQueryOptions = (page_id: string, context?: RequestContext) => ({
  getNextPageParam: (last_page) => {
    if (last_page === null || last_page === undefined) {
      return undefined;
    }
    if (last_page.has_next === false) {
      return undefined;
    }
    return last_page.end_cursor;
  },
  queryFn: async ({ pageParam = null }) => {
    const {
      data: { page_info },
    } = await getPageInfoForWeb(
      {
        page_id,
        after: pageParam,
      },
      { context },
    );
    return page_info;
  },
  onSuccess: (successData: unknown) => {
    try {
      if (typeof sessionStorage !== 'undefined') {
        const savedSessionDataOrigin = sessionStorage.getItem(page_info_session_key);
        let savedSessionData: Record<string, unknown> = {};
        if (typeof savedSessionDataOrigin === 'string') {
          savedSessionData = JSON.parse(savedSessionDataOrigin);
        }
        if (typeof savedSessionData[page_id] === 'undefined') {
          savedSessionData[page_id] = { pageParams: [] };
        }

        if (
          savedSessionData &&
          isSavedPageInfoData(savedSessionData, page_id) &&
          isInfinityQueryData(successData) &&
          savedSessionData[page_id].pageParams.length < successData.pageParams.length
        ) {
          savedSessionData[page_id] = successData;
          sessionStorage.setItem(page_info_session_key, JSON.stringify(savedSessionData));
        }
      }
    } catch (error) {
      console.error(error);
    }
  },
  queryKey: ['page_info', page_id],
  staleTime: Infinity,
  cacheTime: Infinity,
});

function isInfinityQueryData(data: unknown): data is { pageParams: unknown[]; pages: unknown[] } {
  if (typeof data === 'object' && data && 'pageParams' in data && data['pageParams'] instanceof Array) {
    return true;
  }
  return false;
}

function isSavedPageInfoData(
  data: unknown,
  page_id: string,
): data is Record<string, { pageParams: unknown[]; pages: unknown[] }> {
  if (data && data instanceof Object) {
    const dataWithPageId = data as unknown as { [page_id: string]: Record<string, unknown> };
    if (
      dataWithPageId?.[page_id] &&
      'pageParams' in dataWithPageId[page_id] &&
      dataWithPageId[page_id].pageParams instanceof Array
    ) {
      return true;
    }
  }
  return false;
}

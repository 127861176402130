import { useQuery } from 'react-query';

import { getQuickMenuInfo } from '../graphql/quick-menu-info.2';
import { GetQuickMenuInfo_page_info_ui_item_list_UxQuickMenu } from '../graphql/quick-menu-info_types.2';

export const useGetQuickMenuQuery = () =>
  useQuery(['getQuickMenuInfo', 'web_home_quick'], () => getQuickMenuInfo({ page_id: 'web_home_quick' }), {
    select: ({ data }) =>
      data.page_info?.ui_item_list
        .filter(
          (value): value is GetQuickMenuInfo_page_info_ui_item_list_UxQuickMenu => value.__typename === 'UxQuickMenu',
        )
        .flatMap(({ menu_items }) => menu_items),
  });

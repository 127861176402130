import { useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Autoplay, Pagination, Swiper, SwiperSlide } from '@common/carousel/swiper';
import { SWIPER_STYLE, Typography } from '@common/styles';

import { GetWebBannerList_web_banner_list_item_list as BannerListItem } from '../graphql/banner_types.2';
import { BannerItem } from '../services/useMainBannerService';
import { BannerCount } from './Count';

interface MainBannerProps {
  bannerList: BannerListItem[];
  onItemClick: (item: BannerItem) => void;
  onItemIntersect: (index: number) => void;
}

export const MainBanner = ({ bannerList, onItemClick, onItemIntersect }: MainBannerProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const totalBannerCount = bannerList?.length || 0;
  const hasText = (item: BannerListItem) => Boolean(item.main_title_first || item.main_title_second || item.sub_title);

  return (
    <MainBannerSection>
      <Swiper
        modules={[Autoplay, Pagination]}
        autoplay={{
          delay: 4 * 1000,
          disableOnInteraction: false,
        }}
        onInit={({ realIndex }) => onItemIntersect(realIndex)}
        onSlideChangeTransitionStart={({ realIndex }) => setActiveIndex(realIndex)}
        onSlideChangeTransitionEnd={({ realIndex }) => onItemIntersect(realIndex)}
        pagination
        loop
      >
        {totalBannerCount && <BannerCount activeIndex={activeIndex} totalCount={totalBannerCount} />}
        {bannerList?.map((item) => (
          <SwiperSlide key={item.id}>
            <Banner onClick={() => onItemClick({ ...item, index: activeIndex })}>
              <img src={item.image_url || ''} alt={item.main_title_first || ''} />
              {hasText(item) && (
                <>
                  <Dimmed />
                  <div>
                    <h2 className={Typography.HEAD_24_SEMIBOLD}>{item.main_title_first}</h2>
                    {item.main_title_second && (
                      <h3 className={Typography.HEAD_24_SEMIBOLD}>{item.main_title_second}</h3>
                    )}
                    {item.sub_title && <p className={Typography.BODY_13_MEDIUM}>{item.sub_title}</p>}
                  </div>
                </>
              )}
            </Banner>
          </SwiperSlide>
        ))}
      </Swiper>
    </MainBannerSection>
  );
};

const MainBannerSection = styled.section`
  ${SWIPER_STYLE};
  border-radius: 10px;

  .swiper-wrapper {
    display: flex;
    align-items: center;
  }

  .swiper-pagination-bullet {
    display: none;
  }
`;

const Dimmed = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 200px;
  border-radius: 10px;
  opacity: 0.3;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
`;

const MobileMainBanner = css`
  h2 + h3 {
    margin-top: 5px;
  }

  h3 + p {
    padding-bottom: 10px;
  }

  p {
    margin-top: 12px;
  }
`;

const PcMainBanner = css`
  h2,
  h3 {
    font-size: 32px;
    font-weight: 600;
    line-height: 140%;
  }

  h2 + h3 {
    margin-top: 0px;
  }

  p {
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
  }
`;

const Banner = styled.div`
  position: relative;
  margin: 0 16px;
  border-radius: 10px;
  aspect-ratio: 1;

  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    display: block;
    object-fit: cover;
    cursor: default;
  }

  div {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 32px 42px;
    color: white;

    display: flex;
    flex-direction: column;
    align-items: center;
    ${MobileMainBanner}

    @media screen and (min-width: 600px) {
      ${PcMainBanner}
    }
  }
`;
